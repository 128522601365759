// i18next-extract-mark-ns-start bono-cultural-pasarela-de-pago
import {SEO} from 'components/SEO';

//Images
import React from 'react';
import styled from 'styled-components';
import {ScreenSizes} from '../../types/responsive';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {Content} from 'components/Content';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {SignUpButton} from '../components/SignUpButton';
import {ContactSalesButton} from '../components/ContactSalesButton';
import {graphql} from 'gatsby';
import bonoCultural from '../images/pasarela-pago-bono-cultural.png';

const IndexBackground = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(-58deg);
    left: 70%;
    top: 0;
    height: 770px;
    width: 1150px;
    z-index: -1;
    background: #aab4e9;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;
    @media (max-width: ${ScreenSizes.sm}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const FirstSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;

  h2 {
    margin-bottom: 24px;
  }
`;

const IndexContent = styled.div`
  text-align: center;
`;

const Index: React.FC = () => {
  const {t} = useI18next();

  return (
    <>
      <SEO
        path="bono-cultural-pasarela-de-pago"
        preview={bonoCultural}
        title={t('Payment gateway for the Cultural Bonus')}
        description={t(
          'With the MONEI payment gateway, you can collect Cultural Voucher payments quickly and securely, both online and in person.'
        )}
      />

      <IndexBackground>
        <Content>
          <Section>
            <IndexContent>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Payment gateway for Cultural Bonus</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                The Cultural Bonus in Spain is a government initiative aimed at promoting the
                consumption of cultural products and services among young people. This bonus offers
                a sum of money to young people who turn 18 that they can spend on cultural
                activities and products such as books, tickets to concerts, cinema, theatre, music,
                video games, among others. Below is a detail of how it works from a technical
                perspective in terms of payment:
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
          </Section>
        </Content>
        <Content>
          <FirstSectionHeader>
            <Trans parent="h2">Bonus Request</Trans>
            <Trans parent="p">
              <strong>Requirements:</strong> Young people who turn 18 can request the bonus through
              a specific platform enabled by the government.
            </Trans>
            <Trans parent="p">
              <strong>Registration: </strong> They must register on the platform by providing their
              personal data and verifying their identity through authentication systems.
            </Trans>
          </FirstSectionHeader>
        </Content>
        <Content>
          <FirstSectionHeader>
            <Trans parent="h2">Bonus Issuance</Trans>
            <Trans parent="p">
              <strong>Amount:</strong> The bonus grants a fixed amount of money, usually 400 euros,
              to each beneficiary.
            </Trans>
            <Trans parent="p">
              <strong>Format:</strong> The bonus can be issued in digital format through a prepaid
              card or a mobile application.
            </Trans>
          </FirstSectionHeader>
        </Content>
        <Content>
          <FirstSectionHeader>
            <Trans parent="h2">Use of the Bonus</Trans>
            <Trans parent="p">
              <strong>Adhered Establishments:</strong>The voucher can only be used in establishments
              and platforms that have joined the program. These places must register and be approved
              by the administration.
            </Trans>
            <Trans parent="p">
              <strong>Products and Services:</strong> It must be allocated to cultural products and
              services defined by the program, such as books, tickets to cultural events, music,
              cinema, theater, museums, dance, video games, etc.
            </Trans>
          </FirstSectionHeader>
        </Content>

        <Content>
          <FirstSectionHeader>
            <Trans parent="h2">Payment Process</Trans>
            <Trans parent="p">
              <strong>Validation:</strong> When making a purchase, the establishment validates the
              bonus through an application or online platform that verifies the balance and
              eligibility of the product or service.
            </Trans>
            <Trans parent="p">
              <strong>Balance Deduction:</strong> The purchase amount is deducted from the available
              balance in the beneficiary's bonus.
            </Trans>
            <Trans parent="p">
              <strong>Transaction Record:</strong> All transactions are recorded on the cultural
              bonus platform for tracking and auditing.
            </Trans>
          </FirstSectionHeader>
        </Content>

        <Content>
          <FirstSectionHeader>
            <Trans parent="h2">Reimbursement to Establishments</Trans>
            <Trans parent="p">
              <strong>Billing:</strong> Establishments must present the billing of purchases made
              with the bonus to the administration.
            </Trans>
            <Trans parent="p">
              <strong>Payment to Establishments:</strong> The administration processes payments and
              reimburses establishments the amount corresponding to purchases made with the bonus.
            </Trans>
          </FirstSectionHeader>
        </Content>
        <Content>
          <FirstSectionHeader>
            <Trans parent="h2">Control and Audit</Trans>
            <Trans parent="p">
              <strong>Supervision:</strong> Control and audit mechanisms are implemented to ensure
              the correct use of the bonus, avoiding fraud and ensuring that it is allocated to
              eligible cultural products and services.
            </Trans>
            <Trans parent="p">
              <strong>Reports:</strong> Periodic reports are generated on the use of the bonus,
              including consumption statistics and analysis of the program's impact.
            </Trans>
            <Trans parent="p">
              The Cultural Bonus aims not only to incentivize cultural consumption among young
              people, but also to support cultural and creative industries, ensuring that a new
              generation has access and appreciation for culture in its various forms.
            </Trans>
          </FirstSectionHeader>
        </Content>
      </IndexBackground>
    </>
  );
};

export default Index;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "bono-cultural-pasarela-de-pago"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
